import { useLocalStorage } from "@/hooks/useLocalStorage";
import React, { createContext, useEffect, useLayoutEffect } from "react";

const enum ThemeMode {
  Default = "default",
  Dark = "dark",
  Light = "light",
}

const enum ThemeColor {
  Dark = "#000000",
  Light = "#ffffff",
}

interface ThemeI {
  theme: ThemeMode;
  theme_color: ThemeColor;
}

interface ThemeContextType {
  theme: ThemeMode;
  theme_color: ThemeColor;
  setTheme: (theme: ThemeMode) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  theme: ThemeMode.Light,
  theme_color: ThemeColor.Light,
  setTheme: () => {},
});

function ProviderTheme({ children }: { children: React.ReactNode }) {
  const getSystemColorPreference = () => {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  };

  const [{ theme, theme_color }, setThemeObj] = useLocalStorage<ThemeI>(
    "theme",
    {
      theme: ThemeMode.Default,
      theme_color: getSystemColorPreference()
        ? ThemeColor.Dark
        : ThemeColor.Light,
    },
  );

  const updateThemeAttr = (new_theme_color: string) => {
    document.body.setAttribute("data-theme", theme);

    const metaThemeColor = document.querySelector('meta[name="theme-color"]');

    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", new_theme_color);
    } else {
      const newMetaTag = document.createElement("meta");
      newMetaTag.name = "theme-color";
      newMetaTag.content = new_theme_color;
      document.head.appendChild(newMetaTag);
    }
  };

  const setTheme = (new_theme: ThemeMode) => {
    const systemColorPreference = getSystemColorPreference();
    let new_theme_color: ThemeColor = null;

    if (theme === ThemeMode.Default) {
      new_theme_color = systemColorPreference
        ? ThemeColor.Dark
        : ThemeColor.Light;
    } else {
      new_theme_color =
        new_theme === ThemeMode.Dark ? ThemeColor.Dark : ThemeColor.Light;
    }
    updateThemeAttr(new_theme_color);
    setThemeObj({ theme: new_theme, theme_color: new_theme_color });
  };

  useEffect(() => {
    const systemColorScheme = window.matchMedia("(prefers-color-scheme: dark)");

    const systemColorSchemeCallback = (event: MediaQueryListEvent) => {
      setTheme(theme);
    };

    systemColorScheme.addEventListener("change", systemColorSchemeCallback);

    return () => {
      systemColorScheme.removeEventListener(
        "change",
        systemColorSchemeCallback,
      );
    };
  }, [theme]);

  useLayoutEffect(() => {
    updateThemeAttr(theme_color);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        theme_color: theme_color,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

const useThemeContext = () => {
  return React.useContext(ThemeContext);
};

export { ProviderTheme, ThemeMode, useThemeContext };
export default ThemeContext;
