import { subscribeWebPush } from "@/api/endpoints/notifications";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import WebPushService from "@/services/webPush";
import {unsubscribeWebPush} from '../api/endpoints/notifications';

type PermissionI = "default" | "denied" | "granted";

export const useManageNotifications = () => {
  const isSupported = WebPushService.isSupported();

  const [permission, setPermission] = useLocalStorage<PermissionI>(
    "notificationPermission",
    isSupported ? "default" : "denied",
  );

  const enableNotifications = async () => {
    const previousPermission = permission;
    try {
      setPermission("granted");
      if (!WebPushService.hasPermission()) {
        await WebPushService.requestNotificationPermission();
      }
      let subscription = await WebPushService.getSubscription();

      if (!subscription) {
        subscription = await WebPushService.subscribe();
      }
      await subscribeWebPush({ subscription: subscription });
    } catch (error) {
      setPermission(previousPermission);
    }
  };

  const disableNotifications = async () => {
    const previousPermission = permission;
    try {
      setPermission("denied");
      const subscription = await WebPushService.getSubscription()
      const payload = await WebPushService.unsubscribe();
      if(payload) {
        await unsubscribeWebPush({subscription: subscription});
      }

    } catch (error) {
      console.error("Notifications Error: ", error);
      setPermission(previousPermission);
    }
  };

  return {
    isGranted: permission === "granted",
    isDefault: permission === "default",
    isDenied: permission === "denied",
    areNotificationsSupported: WebPushService.isSupported(),
    enableNotifications,
    disableNotifications,
  };
};
